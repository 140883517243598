import { axiosBackEnd, getCookie } from "../utils/helper"
import { TOKEN } from "../utils/constant"
import { message } from "antd"

export const getPlatformRecords = async (currentPage, pageSize) => {
  const token = getCookie(TOKEN)
  if (token === null) {
    message.warn("请登录之后进行操作")
    return
  }
  return await axiosBackEnd.get(
    `/platformRecords?currentPage=${currentPage}&pageSize=${pageSize}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : null
  )
}
