import React from "react"
import { Table, message, Tooltip, Modal } from "antd"

import { getPlatformRecords } from "../api/platformRecords"
import Layout from "../layouts"
import { ENTER_DELAY, FIRST_PAGE, LEAVE_DELAY, PLATFORM_RECORDS_PAGE_SIZE, PLATFORM_RECORD_STATUS } from "../utils/constant"
import logo from "../icons/logo.svg"
import "./platform-records.css"
import { COMMUNITY_URL } from "gatsby-env-variables"
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons"

class PlatformRecordList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      total: 0,
    }
  }

  componentWillMount() {
    // 避免构建失败
    if (typeof window === "undefined" || typeof document === 'undefined') {
      return;
    }
    this.initPlatformRecordList()
  }

  initPlatformRecordList = async () => {
    const res = await getPlatformRecords(FIRST_PAGE, PLATFORM_RECORDS_PAGE_SIZE)
    const data = res.data.data
    if (data) {
      const { list, pageNum } = data
      this.setState({ list, total: pageNum })
    } else {
      message.error("加载平台发布记录列表出错")
    }
  }

  onPaginationChange = async (page) => {
    const res = await getPlatformRecords(page, PLATFORM_RECORDS_PAGE_SIZE)
    const data = res.data.data
    if (data) {
      const { list, pageNum } = data
      this.setState({ list, total: pageNum })
    } else {
      message.error("加载平台发布记录列表出错")
    }
  }

  handlePlarformRecord = (e, record) => {
    if (record.status === PLATFORM_RECORD_STATUS.FAILURE) {
      Modal.error({
        title: '发布失败',
        content: record.statusReason,
      });
    }
  }

  render() {
    const { list, total } = this.state
    const columns = [
      {
        title: "批次",
        dataIndex: "times",
        key: "times",
      },
      {
        title: "文章标题",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "分发时间",
        dataIndex: "createTime",
        key: "createTime",
      },
      {
        title: "分发平台详情（点击logo查看文章）",
        dataIndex: "platformRecordVOList",
        key: "platformRecordVOList",
        render: platformRecordVOList => {
          let records = platformRecordVOList.map(record => (
            <a
              className="mdnice-platform-records-logo"
              href={record.articleUrl}
              target="_blank"
              onClick={(e) => this.handlePlarformRecord(e, record)}
            >
              <Tooltip
                placement="bottom"
                mouseEnterDelay={ENTER_DELAY}
                mouseLeaveDelay={LEAVE_DELAY}
                title={record.platform}
              >
                <div className="mdnice-distri-platform-container">
                  <img
                    src={record.icon}
                    className="mdnice-distri-platform-logo"
                  />
                  {record.status === PLATFORM_RECORD_STATUS.RUNNING && <SyncOutlined className="mdnice-distri-platform-status" spin/>}
                  {record.status === PLATFORM_RECORD_STATUS.SUCCESS && <CheckCircleTwoTone className="mdnice-distri-platform-status" twoToneColor="#52c41a" />}
                  {record.status === PLATFORM_RECORD_STATUS.FAILURE && <CloseCircleTwoTone className="mdnice-distri-platform-status" twoToneColor="red" />}
                </div>
              </Tooltip>
            </a>
          ))
          records.unshift(
            <a
              href={COMMUNITY_URL + "/writing/" + platformRecordVOList[0].outId}
              target="_blank"
            >
              <Tooltip
                placement="bottom"
                mouseEnterDelay={ENTER_DELAY}
                mouseLeaveDelay={LEAVE_DELAY}
                title="墨滴社区"
              >
                <div className="mdnice-distri-platform-container">
                  <img src={logo} className="mdnice-distri-platform-logo" />
                  <CheckCircleTwoTone className="mdnice-distri-platform-status" twoToneColor="#52c41a" />
                </div>
              </Tooltip>
            </a>
          )
          return <div className="mdnice-platform-detail-container">{records}</div>
        },
      },
    ]
    return (
      <Layout isSpacerDisplay={true} title="分发记录">
        <div className="mdnice-platform-records-container">
          <div className="mdnice-platform-records-table-container">
            <Table
              bordered
              columns={columns}
              dataSource={list}
              pagination={{ onChange: this.onPaginationChange, total }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default PlatformRecordList
